import './index.css';
import './utils/fpsMonitor';
import './utility';
import EveryMatrixMessageManager from './platforms/everyMatrix/EveryMatrixMessageManager';
import MessageManager from './messageManager/MessageManager';
import PartnerConfig from './platforms/PartnerConfig';
import { getUrlParam } from './utils/url';
import LocalizationManager from './i18n/LocalizationManager';
import FreeBetsController from './freeBets/freeBetsController';
import GameLimitsWindow from './gameLimitsWindow/gameLimitsWindow';
import ControllerUi from './ui';
import ControllerRulesWindow from './rules/ControllerRules';
import UserAgentDetector from './utils/UserAgentDetector';
import ScaleManager from './scaleManager/ScaleManager';
import Fullscreen from './fullscreen/fullscreen';
import RealityCheck from './realityCheck';
import SystemUi from './ui/uiGroups/system/systemUi';
import './productionFeatures/productionFeatures'
import WrapperModel from './model/wrapperModel';
import ManagerServerCommunication from './api/managerServerCommunication';
import ControllerAutoPlay from './autoPlay/ControllerAutoPlay';

class OPWrapperService {
  constructor(config) {
    this.initConfig = {
      onButtonClick: () => {
      },
      gameContainer: document.getElementById('#container'),
      ...config
    };
    this._blockViewportScale();
    this.config = new PartnerConfig();
    this.model = new WrapperModel({
      initialData: {
        partnerConfig: this.config,
        initConfig: this.initConfig,
      }
    });
    this.serverManager = new ManagerServerCommunication(this.model);
    // this._init();
    this.openedWindows = [];
    this.errors = window.OPUtility.errorTypes;

    window.OPWrapperService = this;

    const id = 'opMainContainer';
    document.body.insertAdjacentHTML('beforeend', `<div id="${id}" class="opWrapper"></div>`);
    this.view = document.getElementById(id);

    LocalizationManager.init(config.localizations, config.lang, config.localizationBundle, config.fallbackLang);

    this.eventManager = window.OPUtility.eventManager;

    if (getUrlParam('pid') === '322') {
      new EveryMatrixMessageManager();
    } else {
      new MessageManager();
    }

    this.messageWindow = window.OPUtility.messageWindow;
    this.Fullscreen = new Fullscreen(this.view);
    this.ControllerStatistic = window.OPUtility.ControllerStatistic;
    this.infoPanel = this.ControllerStatistic; //legacy for OPWrapperService.infoPanel.setGameState();
    this.SystemUI = new SystemUi({ config: { sidebar: { buttons: this.initConfig.buttons } }, partnerConfig: this.config }).Interface;
    this.model.onChange.add(this.SystemUI.handleModelChange);

    this.ScaleManager = ScaleManager;
    this.UserAgentDetector = UserAgentDetector;

    window.addEventListener('online', this._handleInternetOfflineEvent.bind(this));
    window.addEventListener('offline', this._handleInternetOfflineEvent.bind(this));
  }

  async init({ partnerConfig, tournaments, currencyInfo, language }) {
    this.config.updateData(partnerConfig, language);
    this._currencyInfo = Object.freeze(currencyInfo);
    this.initConfig.lang = this.config.lang || this.initConfig.lang;

    this.model.update(current => ({
      ...current,
      partnerConfig: this.config,
      initConfig: this.initConfig,
      currencyInfo: this._currencyInfo,
      tournamentsInfo: tournaments,
      gameInited: true,
    }));

    await LocalizationManager.changeLocalization(this.initConfig.lang);
    this.initControllers();

    if (this.config.showRulesOnStart) this.rulesWindow.show();
  }

  initControllers() {
    this.gameLimitsWindow = new GameLimitsWindow(this.view);
    this.realityCheck = new RealityCheck(this.view);
    this.autoPlay = new ControllerAutoPlay();
    this.freeBetsController = new FreeBetsController(this.view);
    this.rulesWindow = new ControllerRulesWindow(this.view, {
      bundle: this.initConfig.bundle,
      lang: this.initConfig.lang
    });

    this.ControllerStatistic.init(this.config, this.initConfig);
    this.ControllerStatistic.onUpdate.add(this.realityCheck.onStatisticUpdate);

    // this.sideBar.init();
  }

  initUi(config) {
    if (this.ControllerPlayerUi) {
      console.error('UI already created');
      return this.ControllerPlayerUi;
    }

    this.ControllerPlayerUi = new ControllerUi({
      config,
      partnerConfig: this.config
    }).init({ scaleData: this.ScaleManager.currentData });
    this.model.onChange.add(this.ControllerPlayerUi.handleModelChange);
    return this.ControllerPlayerUi;
  }

  toggleOpenedWindow(window) {
    if (this.openedWindows.includes(window)) {
      return this.openedWindows = this.openedWindows.filter(el => el !== window);
    }
    this.openedWindows.push(window);
  }

  showMessage(title, description, showOkButton, needReload) {
    window.OPUtility.messageWindow.show(title, description, showOkButton, needReload);
  }

  showError(code, messageFallback = null, needToReloadForce) {
    window.OPUtility.messageWindow.showError(code, messageFallback, needToReloadForce);
  }

  hideError(code) {
    window.OPUtility.messageWindow.hideError(code);
  }

  showSideBar() {
    this.sideBar.show();
  }

  toggleSidebar() {
    this.SystemUI.toggleControllerVisibility(this.SystemUI.controllerTypes.ECT_SIDEBAR);
    this.ControllerPlayerUi.hud?.closePopups();
  }

  async changeLocalization(lang) {
    await LocalizationManager.changeLocalization(lang);
    this.sideBar.updateButtons();
  }

  showStartPopups() {
    this.ControllerStatistic.downloadStopped();
    this.SystemUI.tryToShowLimitsOnStart(this.config);
    this.realityCheck.init(this.config);

    this.model.update(current => ({
      ...current,
      preloadingComplete: true,
    }));
  }

  _handleInternetOfflineEvent() {
    if (navigator.onLine) return;
    this.showError(this.errors.NO_CONNECTION.CODE);
  }

  _blockViewportScale() {
    window.addEventListener('wheel', (e) => {
      if (e.ctrlKey) {
        e.preventDefault();
      }
    }, {
      'passive': false
    });
  }

  get gameVersion() {
    return this.sideBar.gameVersion;
  }

  set gameVersion(value) {
    this.sideBar.gameVersion = value;
  }

  get localizations() {
    return LocalizationManager;
  }

  get sideBar() {
    return this.SystemUI.controllers[this.SystemUI.controllerTypes.ECT_SIDEBAR];
  }

  set keyDownEventsBlocked(value) {
    this._keyDownEventsBlocked = value;
  }

  get keyDownEventsBlocked() {
    return this._keyDownEventsBlocked;
  }

  get currencyInfo() {
    return this._currencyInfo;
  }
}

export default OPWrapperService;
