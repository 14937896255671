import './styles/index.scss';
import LandscapeUi from './uiGroups/player/landscapeUi';
import LandscapeMobileUi from './uiGroups/player/landscapeMobileUi';
import PortraitUi from './uiGroups/player/portraitUi';

/**
 * UI config example
 @param config = {
  H: {
    init_controls: Boolean
    colors: {
       primary: {
        default: '#000',
        hover: '#000',
        pressed: '#000',
       },
       secondary: {
       ...
       },
       spin: {
       ...
       },
       cancel: {
       ...
       }
       toggled: {
        default: '#000',
       },
       panel: {
        default: '#000'
       }
       overlay: {
        default: '#000'
       }
    },
    offsets: {
      right_panel: { top: 0, left: 0 },
      bottom_panel: { top: 0, left: 0 },
    },
  },

  V: {
    init_controls: Boolean
    colors: ...,
    offsets: {
      top: 0,
      left: 0,
    },
  }
};
 */

export default class ControllerUi {
  constructor({ config, partnerConfig }) {
    this._config = config;
    this._partnerConfig = partnerConfig;
    this._currentOrientation = '';
    this._ui = null;

    this._initializingAdapter = {
      'mobile': {
        'H': LandscapeMobileUi,
        'V': PortraitUi,
      },
      'desktop': {
        'H': LandscapeUi,
        'V': PortraitUi
      }
    };

    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this._onResize, this
    );

    this.handleModelChange = this.handleModelChange.bind(this);
  }

  init({ scaleData }) {
    const { orientation } = scaleData;

    if (!orientation || !this._initializingAdapter[scaleData.isMobile ? 'mobile' : 'desktop'].hasOwnProperty(orientation)) throw new Error(`Unhandled orientation type: ${orientation}`);

    if (this._currentOrientation !== orientation) {
      this._currentOrientation = orientation;

      this._removeCurrentUi();
      const config = this._config && this._config[orientation];
      this._ui = new this._initializingAdapter[scaleData.isMobile ? 'mobile' : 'desktop'][orientation]({ config, partnerConfig: this._partnerConfig });
    }

    this._ui.scaleData = scaleData;
    return this;
  }

  handleModelChange(data) {
    this.hud.handleModelChange(data);
  }

  _onResize(scaleData) {
    this.init({ scaleData });
  }

  _removeCurrentUi() {
    if (this._ui) {
      this._ui.remove();
      this._ui = null;
    }
  }

  get hud() {
    return this._ui.Interface;
  }

  get currentOrientation() {
    return this._currentOrientation;
  }
}
