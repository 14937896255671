import BaseState from "Engine/base/states/BaseState";
import EntryPoint from "Engine/EntryPoint";
import SpinTimeManager from "Engine/base/spinTime/SpinTimeManager";
import ServerManager from '../server/ServerManager';
import GameModel from '../model/GameModel';

export default class RequestRollingState extends BaseState {
    constructor(stateData) {
        super(stateData);

    }

    addListeners() {
        super.addListeners();
    }

    async start() {
        super.start();
        SpinTimeManager.start();
        EntryPoint.GameSettings.quickStop = false;
        OPWrapperService.freeBetsController.decreaseFreeBets();
        OPWrapperService.realityCheck.blockedRealityCheck = true;
        OPWrapperService.ControllerStatistic.bet = GameModel.bet;
        EntryPoint.GameModel.lastBetAmount = GameModel.bet;
        await ServerManager.onStartSpin();
        this.complete();
    }

    onEnd() {
        super.onEnd();
    }
    stop() {
        EntryPoint.GameSettings.quickStop = true;
    }
}
