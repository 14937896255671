import BaseInterface from '../baseInterface';

export default class PlayerUIInterface extends BaseInterface {

  handleModelChange({ tournamentsInfo }) {
    const hasActiveTournaments = tournamentsInfo && tournamentsInfo.active && Boolean(tournamentsInfo.active.length);
    const controllerTournamentLabel = this.controllers[this.controllerTypes.ECT_TOURNAMENTS_LABEL];

    if (hasActiveTournaments) {
      controllerTournamentLabel.show();
    } else {
      controllerTournamentLabel.hide();
    }
  }

  setWinLineConfig(winLineConfig) {
    const winLineLabelController = this.controllers[this.controllerTypes.ECT_WIN_LINE_LABEL];
    if (winLineLabelController) {
      winLineLabelController.setWinLineConfig(winLineConfig);
    }
  }

  showWinLine(winLineData) {
    const winLineLabelController = this.controllers[this.controllerTypes.ECT_WIN_LINE_LABEL];
    if (winLineLabelController) {
      this.hideBottomMainTextsControllers();
      winLineLabelController.show();
      winLineLabelController.setWinLine(winLineData);
    }
  }
  hideWinLine(winLineData) {
    const winLineLabelController = this.controllers[this.controllerTypes.ECT_WIN_LINE_LABEL];
    if (winLineLabelController) {
      winLineLabelController.hide();
    }
  }

  setAvailableBets(bets) {
    const betsController = this.controllers[this.controllerTypes.ECT_BET];
    if (betsController) betsController.setBets(bets);

    const betsTableController = this.controllers[this.controllerTypes.ECT_BET_SELECTOR_TABLE];
    if (betsTableController) {
      betsTableController.setBets(bets);

      const maxBetIndex = betsTableController.maxIndex;
      this.setRecentBets([0, Math.floor(maxBetIndex / 2), maxBetIndex]);
    }
  }

  setRecentBets(recentBets) {
    const betsTableController = this.controllers[this.controllerTypes.ECT_BET_SELECTOR_TABLE];
    if (betsTableController) betsTableController.setRecentBets(recentBets)
  }

  setCurrentBetIndex(betIndex) {
    const betsController = this.controllers[this.controllerTypes.ECT_BET];
    if (betsController) betsController.setCurrentBetIndex(betIndex);

    const betsTableController = this.controllers[this.controllerTypes.ECT_BET_SELECTOR_TABLE];
    if (betsTableController) betsTableController.setCurrentBetIndex(betIndex)
  }

  setBalance(value) {
    const balanceController = this.controllers[this.controllerTypes.ECT_BALANCE];
    if (balanceController) balanceController.setValue(value);
  }

  setLastWin(value) {
    const winController = this.controllers[this.controllerTypes.ECT_WIN];
    if (winController) {
      if (value > 0) {
        this.hideTopMainTextsControllers();
        winController.show();
      }
      winController.lastWin = value;
    }
  }

  setWin(value) {
    const winController = this.controllers[this.controllerTypes.ECT_WIN];
    if (winController) {
      if (value > 0) {
        this.hideTopMainTextsControllers();
        winController.show();
      }
      winController.setValue(value);
    }
  }

  setLabel(value) {
    this.hideTopMainTextsControllers();
    const labelController = this.controllers[this.controllerTypes.ECT_LABEL];
    if (labelController) {
      labelController.show();
      labelController.setValue(value);
    }
  }

  showAutoPlayCount() {
    this.hideBottomMainTextsControllers();
    const AutoPlayCountController = this.controllers[this.controllerTypes.ECT_AUTO_SPIN_COUNT];
    if (AutoPlayCountController) {
      AutoPlayCountController.show();
    }
  }

  hideAutoPlayCount() {
    const AutoPlayCountController = this.controllers[this.controllerTypes.ECT_AUTO_SPIN_COUNT];
    if (AutoPlayCountController) {
      AutoPlayCountController.hide();
    }
  }

  showTotalWin(value) {
    this.hideBottomMainTextsControllers();
    const totalWinContainer = this.controllers[this.controllerTypes.ECT_TOTAL_WIN];
    if (totalWinContainer) totalWinContainer.show(value);
  }

  hideTotalWin() {
    const totalWinContainer = this.controllers[this.controllerTypes.ECT_TOTAL_WIN];
    if (totalWinContainer) totalWinContainer.hide();
  }

  setTotalWin(value) {
    const totalWinContainer = this.controllers[this.controllerTypes.ECT_TOTAL_WIN];
    if (totalWinContainer) {
      if (value > 0) {
        this.hideBottomMainTextsControllers();
        totalWinContainer.show();
      }
      totalWinContainer.setValue(value);
    }
  }

  showFreeSpins(count, text) {
    const spinController = this.controllers[this.controllerTypes.ECT_SPIN];
    const freespinsCountController = this.controllers[this.controllerTypes.ECT_FREESPINS_COUNT];

    if (spinController) spinController.showFreespins(count, text);
    if (freespinsCountController) {
      freespinsCountController.setLabel(freespinsCountController.labelTypes.ELT_FREESPINS_LEFT);
      freespinsCountController.setValue(count);
      freespinsCountController.show();
    }
  }

  hideFreeSpinsLabel() {
    const freespinsCountController = this.controllers[this.controllerTypes.ECT_FREESPINS_COUNT];
    if (freespinsCountController) freespinsCountController.hide();
  }

  hideFreeSpins() {
    const spinController = this.controllers[this.controllerTypes.ECT_SPIN];
    const freespinsCountController = this.controllers[this.controllerTypes.ECT_FREESPINS_COUNT];

    if (spinController) spinController.hideFreespins();
    if (freespinsCountController) {
      freespinsCountController.setLabel(freespinsCountController.labelTypes.ELT_FREESPINS_COMPLETED);
    }
  }

  setFreeSpinsCount(count) {
    const spinController = this.controllers[this.controllerTypes.ECT_SPIN];
    const freespinsCountController = this.controllers[this.controllerTypes.ECT_FREESPINS_COUNT];

    if (spinController) spinController.freespinsCount = count;
    if (freespinsCountController) freespinsCountController.setValue(count);
  }

  toggleSpinButton(enabled) {
    const spinController = this.controllers[this.controllerTypes.ECT_SPIN];
    if (!spinController) return;

    if (enabled) {
      spinController.unblock(spinController.buttonsTypes.EBT_SPIN);
    } else {
      spinController.block(spinController.buttonsTypes.EBT_SPIN);
    }
  }

  toggleStopButton(enabled) {
    const spinController = this.controllers[this.controllerTypes.ECT_SPIN];
    if (!spinController) return;

    if (enabled) {
      spinController.unblock(spinController.buttonsTypes.EBT_STOP);
    } else {
      spinController.block(spinController.buttonsTypes.EBT_STOP);
    }
  }

  toggleAutoPlayButton(enabled) {
    const autoPlayController = this.controllers[this.controllerTypes.ECT_AUTO_SPIN];
    if (!autoPlayController) return;

    if (enabled) {
      autoPlayController.unblock();
    } else {
      autoPlayController.block();
    }
  }

  toggleTurboPlayButton(enabled) {
    const turboPlayController = this.controllers[this.controllerTypes.ECT_TURBO_SPIN];
    if (!turboPlayController) return;

    if (enabled) {
      turboPlayController.unblock();
    } else {
      turboPlayController.block();
    }
  }

  toggleMenuButton(enabled) {
    const menuController = this.controllers[this.controllerTypes.ECT_MENU];
    if (!menuController) return;

    if (enabled) {
      menuController.unblock();
    } else {
      menuController.block();
    }
  }

  toggleInfoButton(enabled) {
    const infoController = this.controllers[this.controllerTypes.ECT_INFO];
    if (!infoController) return;

    if (enabled) {
      infoController.unblock();
    } else {
      infoController.block();
    }
  }

  toggleTournamentsLabel(enabled) {
    const tournamentsLabelController = this.controllers[this.controllerTypes.ECT_TOURNAMENTS_LABEL];

    if (enabled) {
      tournamentsLabelController.unblock();
    } else {
      tournamentsLabelController.block();
    }
  }

  toggleBetSelectors(enabled) {
    const betController = this.controllers[this.controllerTypes.ECT_BET];
    const betSelectController = this.controllers[this.controllerTypes.ECT_BET_SELECT];
    const betSelectorTableController = this.controllers[this.controllerTypes.ECT_BET_SELECTOR_TABLE];
    [betController, betSelectController, betSelectorTableController].forEach(controller => {
      if (!controller) return;

      if (enabled) {
        controller.unblock();
      } else {
        controller.block();
      }
    });
  }

  showSpinButton() {
    const spinController = this.controllers[this.controllerTypes.ECT_SPIN];
    if (spinController) spinController.showSpinButton();
  }

  showTournamentsLabel() {
    const tournamentsLabelController = this.controllers[this.controllerTypes.ECT_TOURNAMENTS_LABEL];
    if (tournamentsLabelController) tournamentsLabelController.show();
  }

  hideTournamentsLabel() {
    const tournamentsLabelController = this.controllers[this.controllerTypes.ECT_TOURNAMENTS_LABEL];
    if (tournamentsLabelController) tournamentsLabelController.hide();
  }

  showStopButton() {
    const spinController = this.controllers[this.controllerTypes.ECT_SPIN];
    if (spinController) spinController.showStopButton();
  }

  setAutoPlayButtonActive(value) {
    const autoPlayController = this.controllers[this.controllerTypes.ECT_AUTO_SPIN];
    if (autoPlayController) autoPlayController.toggleActive(value);
  }

  setTurboPlayButtonActive(value) {
    const turboPlayController = this.controllers[this.controllerTypes.ECT_TURBO_SPIN];
    if (turboPlayController) turboPlayController.toggleActive(value);
  }

  setSoundMuted(value) {
    const soundController = this.controllers[this.controllerTypes.ECT_SOUND];
    if (soundController) soundController.setMuted(value);
  };

  tryActiveAutoPlay() {
    const autoPlayTableController = this.controllers[this.controllerTypes.ECT_AUTO_PLAY_TABLE];
    if (autoPlayTableController) {
      autoPlayTableController.tryActiveAutoPlay();
    }
  }

  closePopups() {
    this._Controller.hideOverlay();
    const betsTableController = this.controllers[this.controllerTypes.ECT_BET_SELECTOR_TABLE];
    const autoPlayTableController = this.controllers[this.controllerTypes.ECT_AUTO_PLAY_TABLE];
    const betsSelectController = this.controllers[this.controllerTypes.ECT_BET_SELECT];
    const autoSpinController = this.controllers[this.controllerTypes.ECT_AUTO_SPIN];
    // const honestyController = this.controllers[this.controllerTypes.ECT_HONESTY];
    if (betsTableController) betsTableController.hide();
    if (autoPlayTableController) autoPlayTableController.hide();
    if (betsSelectController) betsSelectController.reset();
    if (autoSpinController) autoSpinController.toggleActive(false);
    // if (honestyController) honestyController.hide();
  }

  hideTopMainTextsControllers() {
    if (this.topMainTextsControllers) this.topMainTextsControllers.forEach(controller => {
      controller.hide();
    })
  }

  hideBottomMainTextsControllers() {
    if (this.bottomMainTextsControllers) this.bottomMainTextsControllers.forEach(controller => {
      controller.hide();
    })
  }

  get spinButtonEnabled() {
    const spinController = this.controllers[this.controllerTypes.ECT_SPIN];
    return spinController && !spinController.isBlocked(spinController.buttonsTypes.EBT_SPIN);
  }

  get stopButtonEnabled() {
    const spinController = this.controllers[this.controllerTypes.ECT_SPIN];
    return spinController && !spinController.isBlocked(spinController.buttonsTypes.EBT_STOP);
  }

  get tournamentLabelEnabled() {
    const controllerTournamentLabel = this.controllers[this.controllerTypes.ECT_TOURNAMENTS_LABEL];
    return controllerTournamentLabel && !controllerTournamentLabel.isHidden && !controllerTournamentLabel.isBlocked(controllerTournamentLabel.buttonsTypes.EBT_BUTTON_LABEL);
  }

  get topMainTextsControllers() {
    return this._Controller.topMainTextsControllers;
  }

  get bottomMainTextsControllers() {
    return this._Controller.bottomMainTextsControllers;
  }

  get eLabelsTypes() {
      return this.controllers[this.controllerTypes.ECT_LABEL].eLabelsTypes
  }
}
