import EntryPoint from '../../../EntryPoint';
import GlobalDispatcher from '../../../events/GlobalDispatcher';
import SoundManager from '../../../soundManager/SoundManager';
import StatesManager from '../../../base/states/StatesManager';
import eStateTypes from '../../../enums/eBaseStateTypes';
import eEventTypes from '../../../enums/eBaseEventTypes';

export default class BaseUiAdapter {
  constructor(ControllerUi) {
    this._controllerUi = ControllerUi;
    this._currentOrientation = this._controllerUi.currentOrientation;
    this._uiVisible = true;
    this._isFirstSpin = true;

    this._onAutoPlayChange = this._onAutoPlayChange.bind(this);

    this.initStateHandlers();
    this.initExtraStateHandlers();
    this.addListeners();
  }

  init(config) {
    this._initConfig = config || this._initConfig;
    const winLineConfig = this._initConfig.winLineConfig;
    this._bonusLabelText = this._initConfig.bonusLabelText;
    this._buttonClickSound = this._initConfig.clickSound;

    this.syncAvailableBets();
    this.ControllerUi.setCurrentBetIndex(EntryPoint.GameModel.betIndex);
    this.ControllerUi.setBalance(EntryPoint.GameModel.balance);
    this.ControllerUi.setLastWin(EntryPoint.GameModel.lastWin);
    this.ControllerUi.setWin(EntryPoint.GameModel.totalWin);
    if (winLineConfig) this.ControllerUi.setWinLineConfig(winLineConfig);

    window.OPWrapperService.autoPlay.quickPlay = EntryPoint.GameSettings.quickSpin;

    let soundMuted = Boolean(EntryPoint.GameSettings.musicMute) || Boolean(EntryPoint.GameSettings.soundMute) || Boolean(EntryPoint.GameSettings.winningMute);
    EntryPoint.GameSettings.soundMute = soundMuted;
    EntryPoint.GameSettings.winningMute = soundMuted;
    EntryPoint.GameSettings.musicMute = soundMuted;
    this.ControllerUi.setSoundMuted(soundMuted);

    if (EntryPoint.GameModel.freespinsEnabled) {
      this.ControllerUi.showFreeSpins(EntryPoint.GameModel.freespins);
      if (EntryPoint.GameModel.freespinsTotalWin > 0) this.ControllerUi.showTotalWin(EntryPoint.GameModel.freespinsTotalWin);
    }

    this.baseOnStateChanged({ params: StatesManager.currentState.stateData.name });

    if (this._uiVisible) {
      this.showUi();
    } else {
      this.hideUi();
    }

    this.addActions();
  }

  addActions() {
    const controllerTypes = this.ControllerUi.controllerTypes;
    const events = this.ControllerUi.events;

    if (events[controllerTypes.ECT_BET]) {
      this.ControllerUi.on(events[controllerTypes.ECT_BET].INCREASE_BUTTON_CLICK, this.onIncreaseBetClick.bind(this));
      this.ControllerUi.on(events[controllerTypes.ECT_BET].DECREASE_BUTTON_CLICK, this.onDecreaseBetClick.bind(this));
    }
    if (events[controllerTypes.ECT_BET_SELECTOR_TABLE]) {
      this.ControllerUi.on(events[controllerTypes.ECT_BET_SELECTOR_TABLE].CHANGE_BET_CLICK, this.onChangeBetClick.bind(this));
    }
    if (events[controllerTypes.ECT_BET_SELECT]) {
      this.ControllerUi.on(events[controllerTypes.ECT_BET_SELECT].SHOW_BETS_CLICK, this.playClickSound.bind(this));
      this.ControllerUi.on(events[controllerTypes.ECT_BET_SELECT].HIDE_BETS_CLICK, this.playClickSound.bind(this));
    }
    if (events[controllerTypes.ECT_SPIN]) {
      this.ControllerUi.on(events[controllerTypes.ECT_SPIN].SPIN_CLICK, this.onSpinClick.bind(this));
      this.ControllerUi.on(events[controllerTypes.ECT_SPIN].STOP_CLICK, this.onStopClick.bind(this));
    }
    if (events[controllerTypes.ECT_AUTO_SPIN]) {
      this.ControllerUi.on(events[controllerTypes.ECT_AUTO_SPIN].AUTO_SPIN_CLICK, this.onAutoPlayClick.bind(this));
    }

    if (events[controllerTypes.ECT_AUTO_PLAY_TABLE]) {
      this.ControllerUi.on(events[controllerTypes.ECT_AUTO_PLAY_TABLE].START_AUTO_SPINS_CLICK, this.onStartAutoPlayClick.bind(this));
    }
    if (events[controllerTypes.ECT_TURBO_SPIN]) {
      this.ControllerUi.on(events[controllerTypes.ECT_TURBO_SPIN].TURBO_SPIN_CLICK, this.onTurboPlayClick.bind(this));
    }
    if (events[controllerTypes.ECT_MENU]) {
      this.ControllerUi.on(events[controllerTypes.ECT_MENU].BURGER_CLICK, this.onMenuClick.bind(this));
    }
    if (events[controllerTypes.ECT_SOUND]) {
      this.ControllerUi.on(events[controllerTypes.ECT_SOUND].SOUND_MUTE_CLICK, this.onSoundClick.bind(this));
      this.ControllerUi.on(events[controllerTypes.ECT_SOUND].SOUND_UNMUTE_CLICK, this.onSoundClick.bind(this));
    }
    if (events[controllerTypes.ECT_INFO]) {
      this.ControllerUi.on(events[controllerTypes.ECT_INFO].INFO_CLICK, this.onPaytableClick.bind(this));
    }
  }

  addListeners() {
    GlobalDispatcher.add(eEventTypes.EBET_STATE_MANAGER__STATE_CHANGED, this.baseOnStateChanged, this);
    GlobalDispatcher.add(eEventTypes.EBET_AUTOPLAY_SETTINGS__ACTIVE_CHANGED, this.onAutoPlaySettingsActiveChanged, this);
    GlobalDispatcher.add(eEventTypes.EBET_BET_CHANGED, this.syncBet, this);
    GlobalDispatcher.add(eEventTypes.EBET_AVAILABLE_BETS_CHANGED, this.syncAvailableBets, this);
    GlobalDispatcher.add(eEventTypes.EBET_SETTINGS__SOUND_MUTE, this.updateSoundMuted, this);
    GlobalDispatcher.add(eEventTypes.EBET_SETTINGS__MUSIC_MUTE, this.updateSoundMuted, this);
    GlobalDispatcher.add(eEventTypes.EBET_SETTINGS__WINNING_MUTE, this.updateSoundMuted, this);
    GlobalDispatcher.add(eEventTypes.EBET_BIG_WIN_ANIMATION_VISIBLE_CHANGE, this.onBigWinsVisibilityChange, this);
    GlobalDispatcher.add(eEventTypes.EBET_PAYTABLE__BACK_CLICK, this.showUi, this);
    GlobalDispatcher.add(eEventTypes.EBET_FREESPIN_IN_STATE__END, this.showUi, this);
    GlobalDispatcher.add(eEventTypes.EBET_FREESPIN_OUT_STATE__END, this.showUi, this);
    GlobalDispatcher.add(eEventTypes.EBET_PURCHASE__CLOSE, this.showUi, this);
    GlobalDispatcher.add(eEventTypes.EBET_PURCHASE_BTN_CLICK, this.hideUi, this);
    GlobalDispatcher.add(eEventTypes.EBET_SHOW_USER_INTERFACE, this.showUi, this);
    GlobalDispatcher.add(eEventTypes.EBET_HIDE_USER_INTERFACE, this.hideUi, this);
    GlobalDispatcher.add(eEventTypes.EBET_SET_WIN_LINE_TO_UI, this.setWinLineToUi, this);
    if (!OPWrapperService.config.disableSpinBySpace) window.addEventListener('keydown', this.onKeyDown.bind(this));
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onGameResized,
      this
    );
    OPWrapperService.autoPlay.onChange.add(this._onAutoPlayChange)
  }

  setWinLineToUi({ params }) {
    const { winLineIds = [], winLineCoef = 0.0, winAmount = 0.0, multipliers } = this._initConfig.winLineAdapter ? this._initConfig.winLineAdapter(params) : params;

    this.ControllerUi.showWinLine(
      {
        betAmount: EntryPoint.GameModel.lastBetAmount,
        winLineCoef,
        winLineIds,
        winAmount,
        multipliers,
      }
    );
  }

  onGameResized(data) {
    if (EntryPoint.mobile) {
      if (this._controllerUi.currentOrientation === this._currentOrientation) return;
      this._currentOrientation = this._controllerUi.currentOrientation;
      this.init();
    }
  }

  onKeyDown(event) {
    if (event.keyCode === 32 && this._uiVisible) {
      if (this.ControllerUi.spinButtonEnabled) {
        return this.onSpinClick();
      }
      if (this.ControllerUi.stopButtonEnabled) {
        return this.onStopClick();
      }
    }
  }

  showUi() {
    this._uiVisible = true;
    this.ControllerUi.show();
  }

  hideUi() {
    this._uiVisible = false;
    this.ControllerUi.hide();
  }

  onBigWinsVisibilityChange({ params: visible }) {
    if (visible) {
      if (EntryPoint.AutoPlaySettings.active) {
        this.ControllerUi.setOpacity(0.5);
      } else {
        this.hideUi();
      }
    } else {
      this.ControllerUi.setOpacity(1);
      this.showUi();
    }
  }

  syncBet() {
    const betIndex = EntryPoint.GameModel.betIndex;
    this.ControllerUi.setCurrentBetIndex(betIndex);
  }

  syncAvailableBets() {
    this.ControllerUi.setAvailableBets(EntryPoint.GameModel.betPossible);
  }

  updateBalance(value) {
    if (value === undefined) value = EntryPoint.GameModel.balance;
    this.ControllerUi.setBalance(value);
  }

  updateSoundMuted() {
    let soundMuted = Boolean(EntryPoint.GameSettings.musicMute) && Boolean(EntryPoint.GameSettings.soundMute) && Boolean(EntryPoint.GameSettings.winningMute);
    this.ControllerUi.setSoundMuted(soundMuted);
  }


  onSoundClick(muted) {
    EntryPoint.GameSettings.soundMute = muted;
    EntryPoint.GameSettings.winningMute = muted;
    EntryPoint.GameSettings.musicMute = muted;
  }

  onAutoPlayClick() {
    this.playClickSound();
  }

  onStartAutoPlayClick() {
    this.playClickSound();
    this.onSpinClick();
  }

  onTurboPlayClick() {
    this.playClickSound();
    window.OPWrapperService.autoPlay.quickPlay = !window.OPWrapperService.autoPlay.quickPlay;
  }

  onStopClick() {
    this.playClickSound();
    if (!window.OPWrapperService.autoPlay.active) {
      this.ControllerUi.toggleStopButton(false);
      this.onSpinClick();
    } else {
      window.OPWrapperService.autoPlay.active = false;
    }
  }

  onAutoPlaySettingsActiveChanged(event) {
    this.ControllerUi.toggleStopButton(event.params);
  }

  onSpinClick() {
    if (window.OPWrapperService.model.data.isSpinBlocked) return;

    this.playClickSound();
    if (StatesManager.currentState.stateData.name === eStateTypes.EBST_IDLE &&
      EntryPoint.GameModel.bet > EntryPoint.GameModel.balance &&
      !EntryPoint.GameModel.isSpinWithoutBalanceCheck
    ) {
      window.OPWrapperService.showError(window.OPWrapperService.errors.INSUFFICIENT_BALANCE_CLIENT.CODE);
      return
    }

    if (window.OPWrapperService.sideBarVisible) {
      window.OPWrapperService.sideBar.hide();
    }
    GlobalDispatcher.dispatch(eEventTypes.EBET_SPIN_CLICK);
  }

  onIncreaseBetClick() {
    this.playClickSound();
    GlobalDispatcher.dispatch(eEventTypes.EBET_UI_CHANGE_BET, EntryPoint.GameModel.betIndex + 1);
  }

  onDecreaseBetClick() {
    this.playClickSound();
    GlobalDispatcher.dispatch(eEventTypes.EBET_UI_CHANGE_BET, EntryPoint.GameModel.betIndex - 1);
  }

  onChangeBetClick(index) {
    this.playClickSound();
    GlobalDispatcher.dispatch(eEventTypes.EBET_UI_CHANGE_BET, index);
  }

  onMenuClick() {
    this.playClickSound();
    window.OPWrapperService.toggleSidebar();
  }

  onPaytableClick() {
    this.playClickSound();
    GlobalDispatcher.dispatch(eEventTypes.EBET_PAYTABLE__CLICK);
    this.hideUi();
  }

  playClickSound() {
    if (this._buttonClickSound) {
      SoundManager.play(this._buttonClickSound.soundName, this._buttonClickSound.volume, false, this._buttonClickSound.group);
    }
  }

  baseOnStateChanged(event) {
    this.onStateChanged(event);
    if (OPWrapperService.config.skipBlocked && !EntryPoint.AutoPlaySettings.active && ![eStateTypes.EBST_IDLE, eStateTypes.EBST_WAIT_USER_INTERACTION].includes(event.params)) {
      this.ControllerUi.showStopButton();
      this.ControllerUi.toggleStopButton(false);
    }
  }

  _onAutoPlayChange() {
    EntryPoint.AutoPlaySettings.active = OPWrapperService.autoPlay.active;
    EntryPoint.GameSettings.quickSpin = window.OPWrapperService.autoPlay.quickPlay;
    this.ControllerUi.setTurboPlayButtonActive(window.OPWrapperService.autoPlay.quickPlay);
    if (!OPWrapperService.autoPlay.active) this.ControllerUi.hideAutoPlayCount();
  }

  onStateChanged(event) {
    const state = event.params;

    if (this.stateHandlers.hasOwnProperty(state)) {
      this.stateHandlers[state]();
    } else {
      this.stateHandlers.default();
    }

    if (this.extraStateHandlers.hasOwnProperty(state)) {
      this.extraStateHandlers[state]();
    } else {
      this.extraStateHandlers.default();
    }

    this.handleExtras();
  }

  initStateHandlers() {
    this.stateHandlers = {
      [eStateTypes.EBST_WAIT_USER_INTERACTION]: () => {
        this.ControllerUi.showFreeSpins(EntryPoint.GameModel.freespins, this._bonusLabelText);
        this.ControllerUi.showSpinButton();
        this.ControllerUi.toggleStopButton(false);
        this.ControllerUi.toggleSpinButton(true);

        this.ControllerUi.toggleAutoPlayButton(false);
        this.ControllerUi.toggleMenuButton(true);
        this.ControllerUi.toggleBetSelectors(false);

        this.ControllerUi.setBalance(EntryPoint.GameModel.balance);
        if (EntryPoint.GameModel.freespinsTotalWin) this.ControllerUi.showTotalWin(EntryPoint.GameModel.freespinsTotalWin);
        this.ControllerUi.toggleTournamentsLabel(true);
      },
      [eStateTypes.EBST_IDLE]: () => {
        if (EntryPoint.GameModel.freespinsEnabled || !EntryPoint.AutoPlaySettings.active) {
          this.ControllerUi.showSpinButton();
        } else {
          this.ControllerUi.showStopButton();
        }
        this.ControllerUi.toggleStopButton(false);
        this.ControllerUi.toggleSpinButton(!EntryPoint.AutoPlaySettings.active && !EntryPoint.GameModel.freespinsEnabled);

        this.ControllerUi.toggleAutoPlayButton(!EntryPoint.AutoPlaySettings.active && !EntryPoint.GameModel.freespinsEnabled);
        this.ControllerUi.toggleMenuButton(!EntryPoint.AutoPlaySettings.active && !EntryPoint.GameModel.freespinsEnabled);
        this.ControllerUi.toggleInfoButton(!EntryPoint.AutoPlaySettings.active && !EntryPoint.GameModel.freespinsEnabled);
        this.ControllerUi.toggleBetSelectors(!EntryPoint.AutoPlaySettings.active && !EntryPoint.GameModel.freespinsEnabled);
        this.ControllerUi.toggleTournamentsLabel(!EntryPoint.AutoPlaySettings.active && !EntryPoint.GameModel.freespinsEnabled);
        this.ControllerUi.setBalance(EntryPoint.GameModel.balance);

        if (EntryPoint.GameModel.freespinsEnabled) {
          this.ControllerUi.setTotalWin(EntryPoint.GameModel.freespinsTotalWin);
          this.ControllerUi.setFreeSpinsCount(EntryPoint.GameModel.freespins);
        }

        if (!OPWrapperService.freeBetsController.isActive) {
          this.syncBet();
        }
      },
      [eStateTypes.EBST_REQUEST_ROLLING]: () => {
        if (this._isFirstSpin) {
          this._isFirstSpin = false;
          this.ControllerUi.setLabel(this.ControllerUi.eLabelsTypes.ELT_GOOD_LUCK);
        }
        // this.ControllerUi.tryActiveAutoPlay();
        this.ControllerUi.closePopups();

        if (EntryPoint.GameModel.freespinsEnabled) {
          this.ControllerUi.showSpinButton();
        } else {
          this.ControllerUi.showStopButton();
          if (window.OPWrapperService.autoPlay.active) this.ControllerUi.showAutoPlayCount();
        }
        this.ControllerUi.toggleStopButton(!OPWrapperService.config.roundDuration);
        this.ControllerUi.toggleSpinButton(false);

        this.ControllerUi.toggleAutoPlayButton(false);
        this.ControllerUi.toggleMenuButton(false);
        this.ControllerUi.toggleInfoButton(false);
        this.ControllerUi.toggleBetSelectors(false);
        this.ControllerUi.toggleTournamentsLabel(false);
        this.ControllerUi.setWin(0);
        this.ControllerUi.hideWinLine();
        if (!OPWrapperService.freeBetsController.isActive && !EntryPoint.GameModel.freespinsEnabled) {
          this.ControllerUi.setBalance(EntryPoint.GameModel.balance - EntryPoint.GameModel.bet);
        }

        if (EntryPoint.GameModel.freespinsEnabled) {
          this.ControllerUi.setFreeSpinsCount(EntryPoint.GameModel.freespins - 1);
        } else {
          this.ControllerUi.hideFreeSpinsLabel();
        }
      },
      [eStateTypes.EBST_MINIMAL_ROLLING]: () => {
        this.ControllerUi.toggleStopButton(!OPWrapperService.config.roundDuration && !EntryPoint.GameSettings.quickSpin);
        this.ControllerUi.toggleSpinButton(false);

        this.ControllerUi.toggleAutoPlayButton(false);
        this.ControllerUi.toggleMenuButton(false);

        this.ControllerUi.toggleBetSelectors(false);
        this.ControllerUi.toggleInfoButton(false);
        this.ControllerUi.toggleTournamentsLabel(false);
      },
      [eStateTypes.EBST_STOP_ROLLING]: () => {
        this.ControllerUi.toggleStopButton(false);
        this.ControllerUi.toggleSpinButton(false);

        this.ControllerUi.toggleAutoPlayButton(false);
        this.ControllerUi.toggleMenuButton(false);
        this.ControllerUi.toggleInfoButton(false);
        this.ControllerUi.toggleTournamentsLabel(false);
        this.ControllerUi.toggleBetSelectors(false);
      },
      [eStateTypes.EBST_FREE_SPINS_IN]: () => {
        this.hideUi();
        this.ControllerUi.showStopButton();
        this.ControllerUi.toggleStopButton(false);
        this.ControllerUi.toggleSpinButton(false);

        this.ControllerUi.toggleBetSelectors(false);
        this.ControllerUi.setBalance(EntryPoint.GameModel.balance);
        this.ControllerUi.showFreeSpins(EntryPoint.GameModel.freespins);
        this.ControllerUi.toggleInfoButton(false);
        this.ControllerUi.toggleTournamentsLabel(false);
      },
      [eStateTypes.EBST_FREE_SPINS_OUT]: () => {
        this.hideUi();
        this.ControllerUi.showStopButton();
        this.ControllerUi.toggleStopButton(false);
        this.ControllerUi.toggleSpinButton(false);

        this.ControllerUi.toggleBetSelectors(false);
        this.ControllerUi.toggleInfoButton(false);
        this.ControllerUi.toggleTournamentsLabel(false);
        this.ControllerUi.setTotalWin(EntryPoint.GameModel.freespinsTotalWin);
        this.ControllerUi.hideFreeSpins();
        this.ControllerUi.hideTotalWin();
      },
      [eStateTypes.EBST_SHOW_WIN]: () => {
        this.ControllerUi.showStopButton();
        this.ControllerUi.toggleStopButton(true);
        this.ControllerUi.toggleSpinButton(false);

        this.ControllerUi.toggleInfoButton(false);
        this.ControllerUi.toggleBetSelectors(false);
        this.ControllerUi.toggleTournamentsLabel(false);
        this.ControllerUi.setWin(EntryPoint.GameModel.totalWin);
      },
      [eStateTypes.EBST_STOP_WIN]: () => {
        this.ControllerUi.toggleStopButton(false);
        this.ControllerUi.setWin(EntryPoint.GameModel.totalWin);
        // if (EntryPoint.GameModel.freespinsEnabled) {
        //   this.ControllerUi.setTotalWin(EntryPoint.GameModel.freespinsTotalWin);
        // }
      },
      default: () => {
        this.ControllerUi.showStopButton();
        this.ControllerUi.toggleStopButton(false);
        this.ControllerUi.toggleSpinButton(false);
        this.ControllerUi.toggleAutoPlayButton(false);
        this.ControllerUi.toggleMenuButton(false);
        this.ControllerUi.toggleBetSelectors(false);
        this.ControllerUi.toggleInfoButton(true);
        this.ControllerUi.toggleTournamentsLabel(false);
      },
    };
  }

  initExtraStateHandlers() {
    if (!this.extraStateHandlers) {
      this.extraStateHandlers = {
        default: () => {
        }
      };
    }
  }

  handleExtras() {
    if (EntryPoint.AutoPlaySettings.active && !EntryPoint.GameModel.freespinsEnabled) {
      this.ControllerUi.showStopButton();
      this.ControllerUi.toggleStopButton(true);
      this.ControllerUi.toggleBetSelectors(false);
    }

    if (OPWrapperService.freeBetsController.isActive) {
      this.ControllerUi.toggleBetSelectors(false);
    }
  }

  get ControllerUi() {
    return this._controllerUi.hud;
  }
}
