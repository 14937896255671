import BaseInterface from '../baseInterface';

export default class SystemUIInterface extends BaseInterface {

  handleModelChange({ partnerConfig, tournamentsInfo, gameInited, preloadingComplete, rounds_history }) {
    this._Controller.partnerConfig = partnerConfig;
    if (!this._controllersInited) {
      this._controllersInited = true;
      this.controllers[this.controllerTypes.ECT_SIDEBAR].init();
    }

    if (gameInited && tournamentsInfo) {
      this.controllers[this.controllerTypes.ECT_TOURNAMENTS].updateData(tournamentsInfo);
      this.controllers[this.controllerTypes.ECT_MODAL_TOURNAMENT_END].updateData(tournamentsInfo);
      this.controllers[this.controllerTypes.ECT_TOURNAMENTS_START_SCREEN].updateData(tournamentsInfo);
    }

    if (preloadingComplete) {
      this.controllers[this.controllerTypes.ECT_TOURNAMENTS_START_SCREEN].canBeShown = true;
      this.controllers[this.controllerTypes.ECT_MODAL_TOURNAMENT_END].canBeShown = true;
    }

    this.controllers[this.controllerTypes.ECT_ROUNDS_HISTORY].updateData(rounds_history);
    this.controllers[this.controllerTypes.ECT_WIN_LIMITS].updateData(partnerConfig);
  }

  showNotification(type, data) {
    this.controllers[this.controllerTypes.ECT_NOTIFICATIONS].showNotification(type, data);
  }

  handleTournamentStart(tournamentId) {
    this.controllers[this.controllerTypes.ECT_TOURNAMENTS_START_SCREEN].handleStart(tournamentId);
    this.controllers[this.controllerTypes.ECT_SIDEBAR].tryInitTournamentsButton();
  }

  handleTournamentEnd(tournamentId) {
    this.controllers[this.controllerTypes.ECT_MODAL_TOURNAMENT_END].handleEnd(tournamentId);
  }

  showTournamentResults(tournamentId) {
    this.controllers[this.controllerTypes.ECT_TOURNAMENTS].openArchiveTournament(tournamentId);
    this.toggleControllerVisibility(this.controllerTypes.ECT_TOURNAMENTS, true);
  }

  tryToShowLimitsOnStart(config) {
    this.controllers[this.controllerTypes.ECT_WIN_LIMITS].tryToShowLimitsOnStart(config);
  }

  toggleBackgroundSoundSettings(enabled) {
    this.controllers[this.controllerTypes.ECT_GAME_SETTINGS].toggleBackgroundSound(enabled);
  }

  toggleGameSoundSettings(enabled) {
    this.controllers[this.controllerTypes.ECT_GAME_SETTINGS].toggleGameSound(enabled);
  }

  toggleWinSoundSettings(enabled) {
    this.controllers[this.controllerTypes.ECT_GAME_SETTINGS].toggleWinSound(enabled);
  }

  toggleControllerVisibility(type, visibility) {
    const controller = this.controllers[type];
    if (!controller) return console.error(`Unhandled controller type: '${type}'`);

    const condition = visibility !== undefined ? visibility : controller.isHidden;

    if (condition) {
      controller.show();
    } else {
      controller.hide();
    }
  }
}
