import BaseController from './basePlayerController';

export default class ControllerSpin extends BaseController {
  constructor({ container, ...props }) {
    super({ ...props });
    this._eButtonsTypes = {
      EBT_SPIN: 'spin_button',
      EBT_STOP: 'stop_button',
    };
    this._eEventTypes =  {
      SPIN_CLICK: this.getEventName(this._eButtonsTypes.EBT_SPIN),
      STOP_CLICK: this.getEventName(this._eButtonsTypes.EBT_STOP),
    };
    this._eElementsTypes = {
      EET_SPIN_ARROW: 'spin_button_arrow',
      EET_SPIN_BUTTON: this._eButtonsTypes.EBT_SPIN,
      EET_STOP_BUTTON: this._eButtonsTypes.EBT_STOP,
    };

    this.init(container);
    this.hideFreespins();
  }

  showFreespins(count, text) {
    if (count) this.freespinsCount = count;
    if (text) this.freespinsText = text;
    this.interactiveElements[this._eElementsTypes.EET_SPIN_BUTTON].classList.add('freespins_count_shown');
  }

  hideFreespins() {
    this.interactiveElements[this._eElementsTypes.EET_SPIN_BUTTON].classList.remove('freespins_count_shown');
  }

  showSpinButton() {
    this.interactiveElements[this._eElementsTypes.EET_SPIN_BUTTON].classList.remove('hidden');
    this.interactiveElements[this._eElementsTypes.EET_STOP_BUTTON].classList.add('hidden');
  }

  showStopButton() {
    this.interactiveElements[this._eElementsTypes.EET_STOP_BUTTON].classList.remove('hidden');
    this.interactiveElements[this._eElementsTypes.EET_SPIN_BUTTON].classList.add('hidden');
  }

  set freespinsCount(count) {
    this.interactiveElements[this._eElementsTypes.EET_SPIN_BUTTON].setAttribute('data-freespins', count);
  }

  set freespinsText(text) {
    // this.set(this._eElementsTypes.EET_FREESPINS_LABEL_TEXT, text);
  }

  _getMarkup() {
    return `<div class="stop_spin_container">
        <!--    spin    -->
        <div>
          <button id=${this.interactiveElementsIds[this._eElementsTypes.EET_SPIN_BUTTON]} class="stop_spin_container__spin hidden" data-color="green" data-freespins="0">
            <svg id=${this.interactiveElementsIds[this._eElementsTypes.EET_SPIN_ARROW]} width="122" height="128" viewBox="0 0 122 128" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M60.88 24.8799C37.4794 24.8799 18.5 43.8516 18.5 67.2599C18.5 90.6682 37.4794 109.64 60.88 109.64C84.2806 109.64 103.26 90.6682 103.26 67.2599C103.26 53.5283 96.7493 41.3276 86.6032 33.5693L97.5368 19.2705C111.951 30.2922 121.26 47.6915 121.26 67.2599C121.26 100.612 94.2194 127.64 60.88 127.64C27.5406 127.64 0.5 100.612 0.5 67.2599C0.5 33.9082 27.5406 6.87988 60.88 6.87988V24.8799Z" fill="white"/>
              <path d="M60.8799 25.71V31.75L84.4199 15.88L60.8799 0V4.28V6.53V25.71Z" fill="white"/>
            </svg>
          </button>
        </div>
        <!--    stop    -->
        <div>
          <button id=${this.interactiveElementsIds[this._eElementsTypes.EET_STOP_BUTTON]} class="stop_spin_container__stop" data-color="cancel">
            <div></div>
          </button>
        </div>
      </div>`
  }
}
